import React, {useState} from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {useUser} from "../../hooks/useUser";
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import InfoPanel from "../../components/common/repeated-page-panels/InfoPanel";
import {allContractsDropdown} from "../contracts/ContractFragments";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormSelect from "../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import {toast} from "react-toastify";
import {allCountiesDropdown} from "../../relay/Fragments";

const LocationInfo = ({location, contracts, counties}) => {
    const UpdateLocationMutation = graphql`
    mutation LocationInfoMutation($id: ID!, $addressLine1: String!, $addressLine2: String!, $city: String!, 
        $state: String!, $postalCode: String!, $legalContractId: ID!, $longitude: DecimalInput, $latitude: DecimalInput, $countyId: StringInput) {
        location {
            modify(input: {id: $id, addressLine1: $addressLine1, addressLine2: $addressLine2, city: $city,
                state: $state, postalCode: $postalCode, legalContractId: $legalContractId, longitude: $longitude,
            latitude: $latitude, countyId: $countyId}) {
                location {
                    ...LocationInfo_Location
                }
            }
        }
    }`
    const locationInfo = useFragment(graphql`
    fragment LocationInfo_Location on Location {
        id
        legalContract {
            id
        }
        applicableVersion {
            name
        }
        addressLine1
        addressLine2
        city
        state
        postalCode
        county {
            id
        }
        longitude
        latitude
    }`, location)
    const allContracts = useFragment(allContractsDropdown, contracts).map((c) => {return {value: c.id, label:c.name}});
    const [readMode, setReadMode] = useState(true);
    const [commitMutation, isInFlight] = useMutation(UpdateLocationMutation);
    const {hasPermission} = useUser();
    const methods = useForm({values: {
        id: locationInfo.id,
        addressLine1: locationInfo.addressLine1,
            addressLine2: locationInfo.addressLine2,
            city: locationInfo.city,
            state: locationInfo.state,
            postalCode: locationInfo.postalCode,
            countyId: locationInfo.county?.id,
            longitude: locationInfo.longitude,
            latitude: locationInfo.latitude,
            legalContractId: locationInfo.legalContract.id,
        }})
    
    const currentState = methods.watch("state");
    const allCounties = useFragment(allCountiesDropdown, counties).map(c => {return {value: c.id, label: c.name, state: c.state}});
    const filteredCounties = allCounties.filter((i) => {return i.state.toLowerCase() === currentState.toLowerCase()}).toSorted((a, b) => a.label.localeCompare(b.label));
    
    const onSubmit = (data) => {
        commitMutation({variables: {...data}, onCompleted: (data) => {
            if(data.location) {
                toast.success("Location updated successfully")
                setReadMode(true);
            }
            }})
    }
    const handleCancelClick = () => {
        methods.reset();
        setReadMode(true);
    }
    return (
        <FormProvider {...methods}>
            <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                <InfoPanel readMode={readMode}
                           onCancelChangesClick={handleCancelClick}
                           name={locationInfo.applicableVersion.name}
                           isInFlight={isInFlight}
                           onUpdateDetailsClick={() => {setReadMode(false)}}
                           showUpdate={hasPermission("Locations.Update")}>
                    <HorizontalFormInput controlId={"addressLine1"} label={"Address 1"} readMode={readMode} autoComplete={"off"} />
                    <HorizontalFormInput controlId={"addressLine2"} label={"Address 2"} readMode={readMode} autoComplete={"off"} />
                    <HorizontalFormInput controlId={"city"} label={"City"} readMode={readMode} autoComplete={"off"} />
                    <HorizontalFormInput controlId={"state"} label={"State"} readMode={readMode} autoComplete={"off"} />
                    <HorizontalFormInput controlId={"postalCode"} label={"Postal Code"} readMode={readMode} autoComplete={"off"} />
                    <HorizontalFormSelect options={filteredCounties} controlId={"countyId"} label={"County"} readMode={readMode} />
                    <HorizontalFormInput controlId={"longitude"} 
                                         label={"Longitude"}
                                         type={"number"} 
                                         registerOptions={{valueAsNumber: true}} 
                                         readMode={readMode}
                                         step={"0.0001"} 
                                         autoComplete={"off"}
                    />
                    <HorizontalFormInput controlId={"latitude"}
                                         label={"Latitude"}
                                         type={"number"}
                                         readMode={readMode}
                                         registerOptions={{valueAsNumber: true}}
                                         step={"0.0001"}
                                         autoComplete={"off"}
                    />
                    <HorizontalFormSelect options={allContracts} controlId={"legalContractId"} label={"Contract"} readMode={readMode} />
                </InfoPanel>
            </Form>
        </FormProvider>
    )
}
LocationInfo.defaultProps = {}
LocationInfo.propTypes = {}
export default LocationInfo